<app-dialog-layout [showFooter]="false">
  <ng-container content>
    <cap-timeline class="lead-logs" [divider]="true" [items]="leadHistoryLogs$ | async">
      <ng-template capTemplate="content" let-timeline="timeline">
        <div class="lead-history">
          <div class="created-by">
            {{ timeline.formatted_created_at }}
            <span *ngIf="timeline.createdByUser?.name" class="user-name">{{ timeline.createdByUser.name }}</span>
          </div>

          <label class="title">{{ 'market_discovery.lead.' + timeline.column + '.label' | translate }}</label>

          <ng-container [ngSwitch]="timeline.column">
            <ng-container *ngSwitchCase="'remarks'">
              <div class="lead-history-value-changed">
                {{
                  'market_discovery.change-log.changed.label'
                    | translate
                      : {
                          entity: 'market_discovery.lead.remarks.label' | translate
                        }
                }}
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div class="lead-history-row old-value">
                <div class="label">
                  {{ 'market_discovery.lead.history.old_value.label' | translate }}
                </div>

                <div class="value">
                  <ng-container [ngSwitch]="timeline.column">
                    <ng-container *ngSwitchCase="'target_at'">
                      {{ timeline.old_value | date : 'dd-MM-yyyy HH:mm' }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'appointment_type_base_data_value_id'">
                      {{ timeline.old_value ? (timeline.old_value | capBaseDataTranslation) : '-' }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <div [innerHTML]="timeline.old_value"></div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <div class="lead-history-row new-value">
                <div class="label">
                  {{ 'market_discovery.lead.history.new_value.label' | translate }}
                </div>

                <div class="value">
                  <ng-container [ngSwitch]="timeline.column">
                    <ng-container *ngSwitchCase="'target_at'">
                      {{ timeline.new_value | date : 'dd-MM-yyyy HH:mm' }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'appointment_type_base_data_value_id'">
                      {{ timeline.new_value ? (timeline.new_value | capBaseDataTranslation) : '-' }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <div [innerHTML]="timeline.new_value"></div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </cap-timeline>
  </ng-container>
</app-dialog-layout>
