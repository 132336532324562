import { LeadDetails } from '@features/project/interfaces/lead-details.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, Observable } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-lead-history-dialog',
  templateUrl: './lead-history-dialog.component.html',
  styleUrls: ['./lead-history-dialog.component.scss'],
})
export class LeadHistoryDialogComponent implements OnInit {
  public leadId: string;
  public leadHistoryLogs$: Observable<LeadDetails[]>;

  constructor(
    public dialogRef: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
    private leadApiService: LeadApiService
  ) {
    this.leadId = this.dialogConfig?.data?.leadId;
  }

  public ngOnInit(): void {
    this.leadHistoryLogs$ = this.leadApiService.getLeadLogs(this.leadId).pipe(
      map((logs) => logs.map((log) => ({
        ...log,
        formatted_created_at: this.getFormattedDate(log.created_at),
      }))),
    );
  }

  private getFormattedDate(date: string): string
  {
    const isAtMidnight = formatDate(date, 'HH:mm', 'en-US') == '00:00';
    const format = isAtMidnight ? 'dd-MM-yyyy' : 'dd-MM-yyyy HH:mm'
    return formatDate(date, format, 'en-US');
  }
}
