import { Component, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { ProjectApiService } from '@features/project/services/project-api.service';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { distinctUntilKeyChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { XyzFormatedResult } from '@features/project/interfaces/xyz.interface';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { FilterMatchMode, LocalStorageService } from '@capturum/ui/api';
import { TableService } from '@shared/services/table.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartOptions } from 'chart.js';
import { formatDate } from '@angular/common';
import User from '@capturum/auth/lib/user.interface';
import { PrintService } from '@shared/services/print.service';
import { AuthService } from '@shared/services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { XyzService } from '../../../xyz/service/xyz.service';
import { Project } from '../../interfaces/project.interface';

@Component({
  selector: 'app-project-xyz',
  templateUrl: './project-xyz.component.html',
  styleUrls: ['./project-xyz.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
})
export class ProjectXyzComponent implements OnInit, OnDestroy {
  @ViewChild(CapturumDynamicFiltersComponent)
  public dynamicFiltersComponent: CapturumDynamicFiltersComponent;

  public options: ChartOptions;
  public xyz$: Observable<XyzFormatedResult>;
  public filterConfig: DynamicFilterConfig;
  public activeFilters: ActiveFilters[] = [];
  public graph$: Observable<ChartData>;
  public projectId: string;
  public project$: Observable<Project>;

  private _user: User;
  private _defaultActiveFilters: ActiveFilters[] = [];
  private localStorage = inject(LocalStorageService);

  constructor(
    private projectService: ProjectApiService,
    private projectStateService: ProjectStateService,
    private tableService: TableService,
    private translateService: TranslateService,
    private authService: AuthService,
    private printService: PrintService,
    private zone: NgZone,
    private ngxPermissionService: NgxPermissionsService,
    private xyzService: XyzService
  ) {
    this._user = this.authService.getUser();
  }

  public ngOnInit(): void {
    this.project$ = this.projectStateService
      .getProject()
      .pipe(filter(Boolean), distinctUntilKeyChanged('id'), shareReplay(1));

    this.xyz$ = this.project$.pipe(
      tap((project: Project) => {
        this.projectId = project.id;
        this.filterConfig = this.getFilterConfig(
          project.id,
          (project.started_at as string) || (project.created_at as string)
        );
        this._defaultActiveFilters = this.activeFilters;
      }),
      switchMap((project) => {
        return this.tableService.getUpdateTable().pipe(
          filter(Boolean),
          map((options) => {
            return {
              options: options?.filters
                ? {
                    ...options,
                    filters: options.filters.map((optionFilter) => {
                      return {
                        ...optionFilter,
                        value:
                          optionFilter.field === 'user_ids' && Array.isArray(optionFilter.value)
                            ? optionFilter.value.join(',')
                            : optionFilter.value,
                      };
                    }),
                  }
                : options,
              project,
            };
          })
        );
      }),
      filter((value: any) => {
        return Boolean(value.options);
      }),
      switchMap(({ project, options }) => {
        let optionFilters = options?.filters;

        if (this.dynamicFiltersComponent) {
          const storageFilter = this.localStorage.getItem(`xyz-${project.id}_filters`);

          if (storageFilter) {
            this.dynamicFiltersComponent.activeFilters = storageFilter;
            optionFilters = storageFilter.map((optionFilter) => {
              return {
                ...optionFilter,
                value:
                  optionFilter.field === 'user_ids' && Array.isArray(optionFilter.value)
                    ? optionFilter.value.join(',')
                    : optionFilter.value,
              };
            });
          } else {
            this.dynamicFiltersComponent.activeFilters = [];
            optionFilters = [];
          }
        }

        return this.projectService.getXyz(project.id, { parameters: optionFilters });
      }),
      shareReplay(1)
    );

    this.graph$ = this.xyz$.pipe(
      map(({ graph }) => {
        return this.xyzService.generateGraphData(graph);
      })
    );
  }

  public ngOnDestroy(): void {
    this.tableService.resetUpdateTable();
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    const updatedFilters = this.getUpdatedFilters(filters);

    this.tableService.updateTableByFilters(updatedFilters);
  }

  public resetFilters(): void {
    // set active filters to default;
    this.activeFilters = this._defaultActiveFilters;
    this.handleFilterChange(this.activeFilters);
  }

  public exportPdf(): void {
    this.printService.setPrintBusy(true);

    this.tableService
      .getUpdateTable()
      .pipe(
        first(),
        switchMap((options) => {
          const filterOptions = options?.filters.map((filter) => {
            if (Array.isArray(filter.value)) {
              filter.value = filter.value.join(',');
            }

            return filter;
          });

          return this.projectService.exportXyz(this.projectStateService.getProjectSnapshot().id, {
            parameters: filterOptions,
          });
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.finished) {
            this.printService.setPrintBusy(false);
          }
        },
        error: () => {
          this.printService.setPrintBusy(false);
        },
      });
  }

  private getFilterConfig(projectId: string, startDate: string): DynamicFilterConfig {
    const accountManagerFilter = {
      field: 'user_ids',
      type: DynamicFilterType.multiselect,
      icon: 'fas fa-user',
      placeholder: this.translateService.instant('market_discovery.lead.account_manager_id.label'),
      styleClass: 'filter-account-manager',
      matchMode: FilterMatchMode.EQUALS,
      label: null,
      options: this.projectService.getAccountManagersList(projectId),
      selectedItemsLabel: '{0} items',
    };

    const filters: DynamicFilterConfig = {
      filters: [
        {
          field: 'start_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.starts_at.label'),
          styleClass: 'filter-calendar',
          matchMode: FilterMatchMode.GREATER_OR_EQUAL,
          label: null,
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
          minDate: new Date(startDate),
          maxDate: new Date(),
        },
        {
          field: 'end_at',
          type: DynamicFilterType.date,
          icon: 'fas fa-calendar',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.ends_at.label'),
          matchMode: FilterMatchMode.LESS_OR_EQUAL,
          label: null,
          styleClass: 'filter-calendar',
          transformValue: (value) => {
            if (value) {
              return formatDate(new Date(value), 'yyyy-MM-dd', 'en');
            }

            return null;
          },
          maxDate: new Date(),
          minDate: this.getDateFilterValue('start_at'),
        },
        {
          field: 'source_ids',
          type: DynamicFilterType.multiselect,
          icon: 'fas fa-globe',
          placeholder: this.translateService.instant('market_discovery.pipeline.filter.source.label'),
          matchMode: FilterMatchMode.EQUALS,
          label: null,
          options: this.projectService.listSources(projectId),
          selectedItemsLabel: '{0} items',
        },
      ],
    };

    if (this.ngxPermissionService.getPermission('project.xyz.filter-user')) {
      return { ...filters, filters: [accountManagerFilter, ...filters.filters] };
    }

    return filters;
  }

  private getDateFilterValue(filterField: string): Date | null {
    const field = this.activeFilters.find((activeFilter) => {
      return activeFilter.field === filterField;
    });

    if (field && field.value) {
      return new Date(field.value);
    }

    return null;
  }

  private getUpdatedFilters(filters: ActiveFilters[]): ActiveFilters[] {
    return filters?.map((activeFilter) => {
      if (activeFilter.field === 'start_at' && activeFilter.value === null) {
        activeFilter.value = this._defaultActiveFilters.find((defaultFilter) => {
          return defaultFilter.field === 'start_at';
        })?.value;
      }

      if (activeFilter.field === 'end_at' && activeFilter.value === null) {
        activeFilter.value = this._defaultActiveFilters.find((defaultFilter) => {
          return defaultFilter.field === 'end_at';
        })?.value;
      }

      return activeFilter;
    });
  }
}
