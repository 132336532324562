<div class="leads-map">
  <ng-template capTemplate="infoWindow" #infoWindowOutlet let-lead>
    <div class="info-window-content">
      <h6 class="info-window-content--header">{{ lead?.name }}</h6>
      <div class="info-window-content--grid">
        <div class="cell-label">{{ 'market_discovery.lead.lead_status_base_data_value_id.label' | translate }}</div>
        <div class="cell-data">{{ lead?.status }}</div>
        <div class="cell-label">{{ 'market_discovery.lead.lead_source_base_data_value_id.label' | translate }}</div>
        <div class="cell-data">{{ lead?.lead_source_base_data_value_id | baseDataValue }}</div>
        <div class="cell-label">{{ 'market_discovery.lead.target_at.label' | translate }}</div>
        <div class="cell-data">{{ lead?.target_at | date : 'dd-MM-yyyy hh:mm' }}</div>
        <div class="cell-label">{{ 'market_discovery.company.zipcode.label' | translate }}</div>
        <div class="cell-data">{{ lead?.zipcode }}</div>
        <div class="cell-label">{{ 'market_discovery.company.city.label' | translate }}</div>
        <div class="cell-data">{{ lead?.city }}</div>
      </div>
    </div>

    <div class="info-window-content--buttons">
      <cap-button
        styleClass="primary fluid"
        [label]="'market_discovery.lead.show_details.label' | translate"
        (onClick)="goToDetailPage(lead)"></cap-button>
    </div>
  </ng-template>

  <google-map #gmap [options]="options" [width]="null" [height]="603" (mapInitialized)="mapInitialized($event)">
    <ng-container *ngIf="data?.leads as leads">
      <map-marker-clusterer
        [imagePath]="markerClustererImagePath"
        [maxZoom]="leads.length > 1000 ? 15 : 10"
        [ignoreHidden]="true"
        [averagerCenter]="true">
        <map-marker
          *ngFor="let lead of leads; let index = index"
          #marker="mapMarker"
          [options]="markerOptions"
          [position]="{ lng: lead?.lon, lat: lead.lat }"
          [icon]="lead.color | mapMarkerIcon"
          (mapClick)="openInfoWindow(marker, index)">
        </map-marker>
      </map-marker-clusterer>
    </ng-container>

    <map-circle [center]="circleCenter" [radius]="circleRadius" [options]="circleOptions"></map-circle>

    <map-info-window [options]="infoWindowOptions">
      <ng-container [ngTemplateOutlet]="infoWindowOutlet" [ngTemplateOutletContext]="infoWindowContext"></ng-container>
    </map-info-window>
  </google-map>
</div>
