import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { BaseDataKeyService, BaseDataValueService, BatchStatusService } from '@capturum/complete';
import { DestroyBase } from '@capturum/shared';
import { CapturumDialogService, FilterMatchMode, MapItem, TableAction, ToastService } from '@capturum/ui/api';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { CapturumInfoTableComponent, InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { DialogActionType } from '@core/enums/dialog-action-type.enum';
import { DialogAction } from '@core/interfaces/dialog-action.interface';
import { TablePaginator } from '@core/interfaces/table-paginator.interface';
import { Lead, LeadsMap } from '@features/lead/interfaces/lead.interface';
import { LeadApiService } from '@features/lead/services/lead-api.service';
import { TransferLeadsDialogComponent } from '@features/project/components/transfer-leads-dialog/transfer-leads-dialog.component';
import { Project } from '@features/project/interfaces/project.interface';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { TranslateService } from '@ngx-translate/core';
import { PrintService } from '@shared/services/print.service';
import { LocalStorageService } from '@shared/services/storage.service';
import { TableService } from '@shared/services/table.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { debounceTime, filter, first, map, shareReplay, skip, tap } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/features/project/services/project-api.service';
import { ChangeLeadsSourceDialogComponent } from '@features/project/components/change-leads-source-dialog/change-leads-source-dialog.component';
import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { reduceValues } from '@shared/helpers/reduce-operator.helper';

@Component({
  selector: 'app-project-leads',
  templateUrl: './project-leads.component.html',
  styleUrls: ['./project-leads.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TableService],
})
export class ProjectLeadsComponent extends DestroyBase implements OnInit, AfterViewInit {
  @ViewChild('dynamicFiltersComponent')
  public dynamicFiltersComponent?: CapturumDynamicFiltersComponent;

  @ViewChild('leadsInfoTable')
  public leadsInfoTable?: CapturumInfoTableComponent;

  public columns: InfoTableColumn[];
  public project$: Observable<Project>;
  public projectLeads$: Observable<Lead[]>;
  public projectLeadsMap$: Observable<LeadsMap>;
  public filterConfig: DynamicFilterConfig;
  public paginator$: Observable<TablePaginator>;
  public hideSelection = true;
  public hideMap = false;
  public selectedRows: Lead[] = [];
  public bulkActions: TableAction[] = [];
  public accountManagers$: Observable<MapItem[]>;
  public projectId: string;
  public tableFilters: ActiveFilters[] = [];
  public metaValueFilters: ActiveFilters[] = [];
  public hasTransferLeadsPermission: boolean;
  public leadPins$: Observable<LeadsMap>;
  public leadSources$: Observable<MapItem[]>;

  private dialogRef: DynamicDialogRef;
  private radiusDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private mapLeadsSubject: Subject<Lead[]> = new Subject<Lead[]>();
  private _apiOptions: ListOptions;

  constructor(
    private leadService: LeadApiService,
    private projectStateService: ProjectStateService,
    private tableService: TableService,
    private translateService: TranslateService,
    private baseDataService: BaseDataValueService,
    private baseDataKeyService: BaseDataKeyService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: CapturumDialogService,
    private projectApiService: ProjectApiService,
    private toastService: ToastService,
    private permissionService: NgxPermissionsService,
    private batchStatusService: BatchStatusService,
    private storageService: LocalStorageService,
    private printService: PrintService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  public ngOnInit(): void {
    this.hasTransferLeadsPermission = !!this.permissionService.getPermission('project.transfer-leads');
    this.paginator$ = this.tableService.getPaginator();
    this.filterConfig = {
      filters: [
        {
          type: DynamicFilterType.input,
          field: 'name',
          icon: 'fas fa-search',
          styleClass: 'filter-search',
          matchMode: FilterMatchMode.LIKE,
          label: null,
          placeholder: this.translateService.instant('market_discovery.company.name.label'),
        },
        {
          type: DynamicFilterType.multiselect,
          field: 'lead_status_base_data_value_id',
          icon: 'fas fa-globe',
          options: this.leadService.xyzStatuses(),
          matchMode: FilterMatchMode.IN,
          label: null,
          placeholder: this.translateService.instant('market_discovery.lead.lead_status_base_data_value_id.label'),
          selectedItemsLabel: '{0} items',
        },
        {
          type: DynamicFilterType.date,
          field: 'target_at',
          icon: 'fas fa-calendar',
          styleClass: 'filter-calendar',
          matchMode: FilterMatchMode.GREATER_OR_EQUAL,
          label: null,
          placeholder: this.translateService.instant('market_discovery.lead.filter.target_at.label'),
        },
        {
          type: DynamicFilterType.date,
          field: 'target_at_to',
          icon: 'fas fa-calendar',
          styleClass: 'filter-calendar',
          matchMode: FilterMatchMode.LESS_OR_EQUAL,
          label: null,
          placeholder: this.translateService.instant('market_discovery.lead.filter.target_at_to.label'),
        },
        {
          type: DynamicFilterType.input,
          field: 'zipcode',
          icon: 'fas fa-mailbox',
          matchMode: FilterMatchMode.LIKE,
          label: null,
          placeholder: this.translateService.instant('market_discovery.company.zipcode.label'),
        },
        {
          type: DynamicFilterType.input,
          field: 'city',
          icon: 'fas fa-city',
          matchMode: FilterMatchMode.LIKE,
          label: null,
          placeholder: this.translateService.instant('market_discovery.company.city.label'),
        },
        {
          type: DynamicFilterType.dropdown,
          field: 'mainCompanyAddress.country_base_data_value_id',
          icon: 'fas fa-earth-europe',
          matchMode: FilterMatchMode.EQUALS,
          label: null,
          options: this.baseDataKeyService.getBaseDataKeyValues('country'),
          placeholder: this.translateService.instant('market_discovery.address.country_base_data_value_id.label'),
        },
        {
          type: DynamicFilterType.dropdown,
          field: 'radius',
          icon: 'fas fa-circle',
          matchMode: FilterMatchMode.LESS,
          label: null,
          dependsOn: ['city', 'zipcode'],
          sortAlphabetically: false,
          options: this.getRadiusOptions(),
          placeholder: this.translateService.instant('market_discovery.lead.radius.label'),
        },
      ],
    };

    this.project$ = this.projectStateService.getProject().pipe(filter(Boolean), shareReplay(1));

    this.leadSources$ = this.project$.pipe(
      switchMap((project) => {
        return this.projectApiService.listSources(project.id);
      })
    );

    this.leadPins$ = this.project$.pipe(
      switchMap((project) => {
        return this.tableService.getUpdateTable().pipe(
          skip(1),
          reduceValues<ListOptions, ListOptions>((acc, value) => {
            return { ...acc, ...value };
          }, {}),
          debounceTime(1000), // collect accumulated values after 1 second
          map((options) => {
            return {
              options,
              project,
            };
          })
        );
      }),
      switchMap(({ project, options }) => {
        return this.projectApiService.getLeadPins(project.id, options).pipe(
          map((leads) => {
            return {
              leads: leads.data,
              filters: options.filters,
              lon: leads.lon,
              lat: leads.lat,
            };
          })
        );
      })
    );

    this.projectLeads$ = this.project$.pipe(
      tap((project) => {
        this.projectId = project.id;
        this.hideMap = !!this.storageService.get(this.getProjectStateKey('map-card'));
        this.hideSelection =
          this.storageService.get(this.getProjectStateKey('selection-card')) === null
            ? true
            : this.storageService.get(this.getProjectStateKey('selection-card'));
      }),
      switchMap((project) => {
        return this.tableService.getUpdateTable().pipe(
          skip(1),
          reduceValues<ListOptions, ListOptions>((acc, value) => {
            return { ...acc, ...value };
          }, {}),
          debounceTime(1000), // collect accumulated values after 1 second
          map((apiOptions) => {
            let options = { ...apiOptions };

            if (!options.sort) {
              options.sort = [
                {
                  field: 'name',
                  direction: 'asc',
                },
              ];
            }

            if (!options.perPage) {
              options.perPage = 20;
            }

            const targetFilters = apiOptions.filters.filter((filter) => {
              return filter.field.includes('target_at');
            });

            if (targetFilters.length) {
              const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

              options = {
                ...options,
                filters: options.filters.map((filter) => {
                  if (filter.field === 'target_at' && filter.value) {
                    filter.value = zonedTimeToUtc(startOfDay(new Date(filter.value)), zone).toISOString();
                  } else if (filter.field === 'target_at_to' && filter.value) {
                    filter.value = zonedTimeToUtc(endOfDay(new Date(filter.value)), zone).toISOString();
                  }

                  return filter;
                }),
              };
            }

            return {
              projectId: project.id,
              ...options,
            };
          })
        );
      }),
      tap(({ filters, sort, page, perPage }) => {
        this._apiOptions = { filters, sort, page, perPage };

        this.storageService.set('apiOptions-project-leads', this._apiOptions);
      }),
      switchMap(({ projectId, filters, sort, page, perPage }) => {
        return this.leadService.getProjectLeads(projectId, {
          include: ['company', 'mainCompanyAddress'],
          filters,
          sort,
          page,
          perPage,
        });
      }),
      tap(({ data }) => {
        return this.mapLeadsSubject.next(data);
      }),
      tap(({ meta }) => {
        this.tableService.setPaginator(meta);
      }),
      map((response) => {
        return response.data;
      }),
      takeUntil(this.destroy$)
    );

    this.columns = [
      {
        title: this.translateService.instant('market_discovery.company.name.label'),
        field: 'name',
        cellClass: 'grey-cell',
      },
      {
        title: this.translateService.instant('market_discovery.lead.lead_status_base_data_value_id.label'),
        field: 'lead_status_base_data_value_id',
        type: InfoTableColumnType.Template,
      },
      {
        field: 'target_at',
        title: this.translateService.instant('market_discovery.lead.target_at.label'),
        type: InfoTableColumnType.Template,
      },
      {
        field: 'mainCompanyAddress.zipcode',
        title: this.translateService.instant('market_discovery.company.zipcode.label'),
      },
      {
        field: 'mainCompanyAddress.city',
        title: this.translateService.instant('market_discovery.company.city.label'),
      },
      {
        field: 'mainCompanyAddress.country_base_data_value_id',
        title: this.translateService.instant('market_discovery.address.country_base_data_value_id.label'),
        type: InfoTableColumnType.Template,
      },
      {
        field: 'distance',
        title: this.translateService.instant('market_discovery.lead.radius.label'),
        type: InfoTableColumnType.Template,
      },
    ];

    this.bulkActions = [
      {
        label: this.translateService.instant('market_discovery.project.change_account_manager.label'),
        icon: 'fas fa-arrow-up-arrow-down',
        key: 'leads_transfer_account_manager',
        permissions: 'project.transfer-leads',
        callback: this.transferLeads.bind(this),
      },
      {
        label: this.translateService.instant('market_discovery.project.change_leads_source.label'),
        icon: 'fas fa-arrow-up-arrow-down',
        key: 'change_leads_source',
        permissions: 'project.change-leads-source',
        callback: this.changeSourceForLeads.bind(this),
      },
    ];

    this.accountManagers$ = this.project$.pipe(
      switchMap((project) => {
        return this.projectApiService.getAccountManagersList(project.id);
      })
    );
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public loadTableData(event: LazyLoadEvent): void {
    if (!event.sortField) {
      event.sortField = 'name';
    }

    this.tableService.updateTableByLazyLoadEvent(event);
  }

  public handleFilterChange(filters: ActiveFilters[]): void {
    let handleFilters = filters;
    const zipcodeFilter = handleFilters.find((filter) => {
      return filter.field === 'zipcode';
    })?.value;
    const cityFilter = handleFilters.find((filter) => {
      return filter.field === 'city';
    })?.value;
    const filteredActiveFilters = handleFilters.filter((filter) => {
      return filter.field !== 'radius';
    });

    if (!(zipcodeFilter || cityFilter)) {
      if (this.dynamicFiltersComponent) {
        this.dynamicFiltersComponent.activeFilters = [...filteredActiveFilters];
      }

      handleFilters = filteredActiveFilters;
    }

    this.tableService.updateTableByFilters([...this.metaValueFilters, ...handleFilters]);
    this.tableFilters = handleFilters;
    this.radiusDisabledSubject.next(!(zipcodeFilter || cityFilter));
  }

  public handleRowToggle(selectedRows: Lead[]): void {
    this.selectedRows = selectedRows;
  }

  public navigateToLeadDetail(row: Lead): void {
    this.router.navigate([`./${row.id}`], { relativeTo: this.route });
  }

  public handleMetaFilterChange(filters: ActiveFilters[]): void {
    this.metaValueFilters = filters;
    this.tableService.updateTableByFilters([...this.tableFilters, ...filters]);
  }

  public exportLeads(): void {
    this.toastService.info(
      this.translateService.instant('market_discovery.lead.export'),
      this.translateService.instant('market_discovery.lead.export.started')
    );

    this.leadService.export(this.projectId, { filters: this._apiOptions.filters }).subscribe();
  }

  public exportLeadsToPdf(): void {
    this.printService.setPrintBusy(true);

    this.toastService.info(this.translateService.instant('market_discovery.pdf.generated'), '');

    this.projectApiService.exportLeads(this.projectId, { ...this._apiOptions }).subscribe((response) => {
      if (response?.finished) {
        this.printService.setPrintBusy(false);
      }
    });
  }

  public toggleSelectionCard(): void {
    this.hideSelection = !this.hideSelection;
    this.storageService.set(this.getProjectStateKey('selection-card'), this.hideSelection);
  }

  public toggleMapCard(): void {
    this.hideMap = !this.hideMap;
    this.storageService.set(this.getProjectStateKey('map-card'), this.hideMap);
  }

  private getRadiusOptions(): Observable<MapItem[]> {
    return this.baseDataKeyService.getBaseDataKeyValues('lead_radius').pipe(
      map((items) => {
        return items.map((item) => {
          return { value: item.key, label: item.label };
        });
      })
    );
  }

  private getCountryOptions(): Observable<MapItem[]> {
    return this.baseDataKeyService.getBaseDataKeyValues('country').pipe(
      map((items) => {
        return items.map((item) => {
          return { value: item.value, label: item.label };
        });
      })
    );
  }

  private getSourceOptions(): Observable<MapItem[]> {
    return this.projectApiService.listSources(this.projectId);
  }

  private transferLeads(leads: Lead[]): void {
    this.dialogRef = this.dialogService.open(TransferLeadsDialogComponent, {
      header: this.translateService.instant('market_discovery.project.update_account_manager.title'),
      styleClass: 'transfer-leads-dialog',
      data: {
        leads,
        accountManagers: this.accountManagers$,
      },
    });

    this.dialogRef.onClose
      .pipe(
        filter((result: DialogAction) => {
          return result.action === DialogActionType.submit;
        }),
        switchMap(({ data }) => {
          return this.projectApiService.transferAccountManager(this.projectId, data);
        }),
        first()
      )
      .subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('market_discovery.entity.toast.updated')
        );

        this.resetSelectedRows();
      });
  }

  private changeSourceForLeads(leads: Lead[]): void {
    this.dialogRef = this.dialogService.open(ChangeLeadsSourceDialogComponent, {
      header: this.translateService.instant('market_discovery.project.change_leads_source.title'),
      styleClass: 'change-leads-source-dialog',
      data: {
        leads,
        sources: this.getSourceOptions(),
      },
    });

    this.dialogRef.onClose
      .pipe(
        filter((result: DialogAction) => {
          return result?.action === DialogActionType.submit;
        }),
        switchMap(({ data }) => {
          return this.projectApiService.changeSourceForLeads(this.projectId, data);
        }),
        first()
      )
      .subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('market_discovery.entity.toast.updated')
        );

        this.resetSelectedRows();
      });
  }

  private resetSelectedRows(): void {
    this.selectedRows = [];

    // Makes sure the selection is updated in table state
    this.leadsInfoTable.primeNGTable.selection = [];
    this.leadsInfoTable.primeNGTable.saveState();
  }

  private getProjectStateKey(key: string): string {
    return `${this.projectId}-${key}`;
  }
}
